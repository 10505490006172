.dash-div{
    background: #fff;
    border-radius: 5px;
    padding: 20px;
}

.btn-view{
    border: 1px solid #0898D7;
    color: #0898D7;
    transition: all ease-in .15s;
}

.btn-view:hover{
    background: #0898D7;
    color: #fff;
}

.success-color{
    background: #00B327;
    border-radius: 100px;
    color: white;
    padding: 3px 25px;
}

.processing-color{
    background: #FEC400;
    border-radius: 100px;
    color: white;
    padding: 3px 25px; 
}

.declined-color{
    background: #ff0000;
    border-radius: 100px;
    color: white;
    padding: 3px 25px;
}

.defaultDiv{
    background: #0898D7;
   border-radius: 100px;
   color: white;
   padding: 3px 25px;
}


/* Media query for smaller phones */

@media (max-width:767px) {
    .success-color{
        color: #40D057;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
    .declined-color{
        color: #ff0000;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
    .defaultDiv{
        color: #0898D7;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
    .processing-color{
        color: #FEC400;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
}