
.settings-input{
    border: 1px solid #0898D7;
    border-radius: 5px;
    padding: 22px 20px;
    background: #fff;
    font-size: 15px;
    color: rgba(0,0,0,.8);
    -webkit-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

  
.settings-input:focus {
    border: 1px solid #0898D7;
    box-shadow: none;
    outline: 0 none;
}
