*{
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Nunito', sans-serif;
}



/* Tacit exchange button styles */
.btn-blueTacit{
  background: #0898D7;
  border-radius: 5px;
  color: white;
  padding: 10px 40px;
  transition: all ease-in .15s;
}

.btn-blueTacit:hover{
  background: #0d92d5;
  color: white;
}

.btn-pinkTacit{
  background: #9d0957;
  border-radius: 5px;
  color: white;
  padding: 10px 40px;
  transition: all ease-in-out .15s;
}

.btn-pinkTacit:hover{
  background-color: #AB0351;
  color: white;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}


