.orderTab{
    cursor: pointer;
}
.tabSpace{
    margin-right: 20px;
}
.activeOrderTab{
  font-weight: 600;
  position: relative;
  cursor: pointer;
}

.activeOrderTab::after{
    content: '';
    height: 2px;
    width: 100%;
    background: #0898D7;
    position: absolute;
    left: 0px;
    bottom: -8px;
}

/* trade by id layout */
.card-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-image{
    width: 200px;
    height: 160px;
    border-radius: 5px;
}

/* payment status */
.notinitStatus{
    background: #0898D7;
    border-radius: 100px;
    color: white;
    padding: 3px 25px;
}

.processingStatus{
    background: #FEC400;
    border-radius: 100px;
    color: white;
    padding: 3px 25px;   
}

.failedStatus{
    background: #ff0000;
    border-radius: 100px;
    color: white;
    padding: 3px 25px; 
}

.successStatus{
    background: #00B327;
    border-radius: 100px;
    color: white;
    padding: 3px 25px; 
}

.modal-contain{
    padding: 80px 40px 30px 40px;
}

.modal-success{
    padding: 80px 40px;
}

.modal-header {
    padding: 0px;
    border-bottom: 0px;
}

.contain-head{
    padding: 0px 40px
}



/* Media query for smaller phones */

@media (max-width:767px) {
    .card-head{
        flex-direction: column;
        align-items: flex-start;
    }
    .notinitStatus{
        color: #0898D7;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
    .processingStatus{
        color: #FEC400;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
    .failedStatus{
        color: #ff0000;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }
    .successStatus{
        color: #00B327;
        background: transparent;
        border-radius: none;
        padding: 0px;
    }

    .modal-contain{
        padding: 20px 40px;
    }
    .modal-success{
        padding: 30px 40px;
    }
    .modal-header{
        padding: 1rem 1em;
    }

}