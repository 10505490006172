
.admin-card{
    width: 50%;
    margin: auto;
    background-color: white;
    border-radius: 10px;
}

.admin-div{
    padding: 50px 80px;
}

.adminprofile{
background: #fff;
border-radius: 5px;
padding: 30px 60px;
flex: 0 0 50%;
max-width: 50%;
margin: 0 auto;
}

.profileImage {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.width{
    width: 80%;
    margin: auto;
}
/* adminprofile styles */
.password-style{
    background: #F5F0FC;
    border-radius: 5px;
    padding: 23px 23px 23px 55px;
    border: none;
  }
  .password-style::placeholder{
    color: #aaaaaa;
  }
  
   .password-style:focus {
      box-shadow: none;
      outline: 0 none;
      background: #F5F0FC;
    }
  
    .password_icon {
      position: absolute;
      left: 20px;
      top: 10px;
      color: #0d92d5;
      cursor: pointer;
    }

  /* input tye file styles */
  .file{
    display: table;
    width: 50%;
    margin: auto;
    background: #0898D7;
    border-radius: 10px;
    color: white;
    padding: 12px 30px;
    font-weight: bold;
    transition: all ease-in 0.5s;
  }
  .file.disabled, .file:disabled {
    opacity: .65;
    pointer-events: none;
}
input[type="file"].adminphoto {
    display: none;
}

.btn-suspend{
  background: #FF3535;
  border-radius: 5px;
  color: white;
  padding: 10px 40px;
  transition: all ease-in 0.5s;
}

.btn-suspend:hover{
  color: white;
}

.btn-active{
  background:#00B327;
  border-radius: 5px;
  color: white;
  padding: 10px 40px;
  transition: all ease-in 0.5s;
}

.btn-active:hover{
  color: white;
}

/* Media query for smaller phones */

@media (max-width:767px) {
    .admin-card{
        width: 100%;
    }
    .admin-div{
        padding-left: 15px;
        padding-right: 15px;
    }
    .adminprofile{
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    .width{
        width: 100%;
    }
  .file{
      width: 100%;
  }
}